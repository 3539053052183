import React, {useState} from 'react'
import styled from 'styled-components'
import {graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import {MainLayout} from 'layouts'
import {SEO, SearchBar} from 'components'
import {useAmplitude} from 'hooks'
import {CreatorLock} from 'images/svgs'

const Browser = styled.div`
  display: flex;
  flex-direction: column;

  width: var(--mobile-width);
  margin: 25px 0;

  @media only screen and (min-width: 1200px) {
    max-width: var(--content-width);
  }
`

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media only screen and (min-width: 725px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 1150px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Title = styled.div`
  margin-bottom: 15px;

  h1 {
    margin: 0;
  }
`

const TopicIcon = styled(Img)`
  margin: 15px;
  margin-left: 0;

  width: 80px;
  flex-shrink: 0;
`

const TopicTagsRow = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
`

const Tag = styled.div`
  display: inline-block;

  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px 10px;
  margin-left: 0px;

  background-color: var(--violet);

  font-family: var(--primary-font);
  font-size: 0.9em;
  font-weight: 600;
`

const StyledLock = styled(CreatorLock)`
  height: 25px;
  margin-right: 10px;
  margin-bottom: 0.2em;
  flex-shrink: 0;
`

const CardStyle = styled(Link)`
  display: flex;
  align-items: center;

  border-radius: 5px;
  padding: 15px;
  width: 100%;

  background-color: var(--white);

  font-size: 0.9em;
  transition: var(--transition);

  &:hover {
    filter: var(--shadow);
  }
`

const TopicInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;

  h5 {
    margin: 0;
    transition: var(--transition);

    @media only screen and (min-width: 700px) {
      ${CardStyle}:hover & {
        color: var(--blue);
      }
    }
  }

  p {
    height: 100%;
  }
`

const SearchBarBox = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

const TopicCard = ({topic}) => (
  <CardStyle to={`/topics/${topic.slug}`}>
    <TopicIcon fluid={topic.gatsbyIcon.childImageSharp.fluid} alt={`${topic.title} icon`} />
    <TopicInfo>
      <h5>{topic.title}</h5>
      <p>{topic.description}</p>
      <TopicTagsRow>
        {!topic.free && <StyledLock />}
        {topic.categories && topic.categories.length !== 0 && <Tag>{topic.categories[0].tag}</Tag>}
      </TopicTagsRow>
    </TopicInfo>
  </CardStyle>
)

export const TopicPage = ({data}) => {
  const {logEvent} = useAmplitude()
  const [topics, setTopics] = useState(data.allStrapiTopic.nodes)

  const logClick = (buttonName, data = {}) => logEvent('topics-page-button-click', {buttonName, ...data})

  return (
    <MainLayout theme='transparent'>
      <SEO title='Topics' />
      <Browser>
        <Title>
          <h1>Topics</h1>
          <SearchBarBox>
            <p>Fundamental concepts which can help you on your journey.</p>
            <SearchBar
              documents={topics}
              setDocuments={setTopics}
              label='Search our topics'
              index={['slug', 'title', 'description', 'categories.tag']}
            />
          </SearchBarBox>
        </Title>
        <CardGrid>
          {topics.length === 0 ? (
            <h2>No results.</h2>
          ) : (
            topics.map((topic) => (
              <TopicCard key={topic.slug} topic={topic} onClick={() => logClick('topic-preview', {slug: topic.slug})} />
            ))
          )}
        </CardGrid>
      </Browser>
    </MainLayout>
  )
}

export default TopicPage

export const Topics = graphql`
  query Topics {
    allStrapiTopic(sort: {fields: free, order: DESC}) {
      nodes {
        slug
        title
        categories {
          tag
        }
        free
        gatsbyIcon {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
        lessons {
          video {
            duration
          }
        }
      }
    }
  }
`
